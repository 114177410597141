<template>
  <navigation></navigation>
  <div>
    <div class="flex justify-center">
      <div class="soviko-container">
        <intro></intro>
        <div class="flex justify-center">
          <div class="text-center font-bold text-gray px-10 md:w-1/2 text-lg md:text-xl">
            Wir sind ein anerkannter Träger der freien Jugendhilfe gem. § 75a SGB VIII und Dienstleistungsunternehmen
            aus
            dem
            Bereich der Kinder- und Jugendarbeit. Bei unserer Arbeit legen wir besonderen Wert auf <a
              class="text-primary" href="#our-offer" v-smooth-scroll>innovative pädagogische
    Ansätze</a>, um die <span class="text-primary" href="#our-offer" v-smooth-scroll>persönliche Entfaltung</span> der uns anvertrauten Kinder und
            Jugendlichen nachhaltig zu fördern.
          </div>
          <div class="" id="our-offer"></div>
        </div>
      </div>
    </div>
    <our-offer></our-offer>
    <our-job></our-job>
    <about-us></about-us>
    <div class="" id="contact"></div>
    <contact></contact>
    <sov-footer></sov-footer>
  </div>
  <vue-scroll-up
      tag="div"
      custom-class="my-scroll-up"
      :scroll-duration="500"
      :scroll-y="250"
      :always-show="false"
  >
    ⬆
  </vue-scroll-up>
<!--  <cookie-consent></cookie-consent>-->
</template>

<script>
import Navigation from './theme/Navigation';
import Intro from './Intro';
import OurOffer from './offer/OurOffer';
import OurJob from './job/OurJob';
import AboutUs from "./about-us/AboutUs";
import Contact from "./contact/Contact";
import SovFooter from "./footer/SovFooter";
import VueScrollUp from 'vue-scroll-up'

export default {
  name: 'Home',
  components: {SovFooter, Contact, AboutUs, OurJob, OurOffer, Intro, Navigation, VueScrollUp},
};
</script>

<style scoped>
.my-scroll-up {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.658);
  border: rgba(0, 0, 0, 0.658);
  border-radius: 100px;
  color: white;
  cursor: pointer;
  display: flex;
  height: 55px;
  justify-content: center;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 55px;
  z-index: 99;
}
</style>
