<template>
  <section-header title="Unsere Tätigkeit" image-button="button_kids.png" image-button-position="left"></section-header>
  <div class="flex justify-center">
    <div class="px-10 py-5 soviko-container">
      <carousel snapAlign="start" class="our-job-slider" :breakpoints="breakpoints">
        <slide :key="1" style="background: #E0DEDA; overflow: hidden" class="px-10 py-10 rounded-2xl md:mr-5">
          <sov-slide header="Jugendpflegeleasing in Dillingen">
            <template #text>
              <div class="hyphens carousel__item">
                Seit Dezember 2012 sind wir für die Große Kreisstadt Dillingen tätig. Im Rahmen der Offenen Kinder- und
                Jugendarbeit betreuen wir das Jugendcafé Dillingen für junge Menschen ab 12 Jahren.
              </div>
              <img :src="require('/src/assets/images/more_button.png')" alt="Mehr"
                   class="h-16 absolute right-0 bottom-0 z-50 cursor-pointer"
                   style="margin-right: -10px; margin-bottom: -10px" v-touch="modalStore.openLeasingDillingen" @click="modalStore.openLeasingDillingen">
            </template>
          </sov-slide>
        </slide>
        <slide :key="2" style="background: #E0DEDA; overflow: hidden" class="px-10 py-10 rounded-2xl md:mr-5">
          <sov-slide header="Jugendpflegeleasing in Immenstadt i. Allgäu">
            <template #text>
              Seit März 2020 leisten wir Offene Kinder- und Jugendarbeit in Immenstadt i. Allgäu. Im Jugendhaus
              Immenstadt
              kümmern wir uns um junge Menschen ab 12 Jahren.
              <img :src="require('/src/assets/images/more_button.png')" alt="Mehr"
                   class="h-16 absolute right-0 bottom-0 z-50 cursor-pointer"
                   style="margin-right: -10px; margin-bottom: -10px" @click="modalStore.openLeasingAllgauu" v-touch="modalStore.openLeasingAllgauu">
            </template>
          </sov-slide>
        </slide>
        <slide :key="3" style="background: #E8F2E1;overflow: hidden" class="px-10 py-10 rounded-2xl md:mr-5">
          <sov-slide header="Offene Ganztagesschule Grundschule Dillingen">
            <template #text>
              Seit dem Schuljahr 2015/16 sind wir Kooperationspartner der Grundschule Dillingen. Dort führen wir das
              schulische Angebot der Offenen Ganztagesschule (OGTS) in Kurz- und Langgruppen durch.
              <img :src="require('/src/assets/images/more_button.png')" alt="Mehr"
                   class="h-16 absolute right-0 bottom-0 z-50 cursor-pointer"
                   style="margin-right: -10px; margin-bottom: -10px" @click="modalStore.openSchuleDillingen" v-touch="modalStore.openSchuleDillingen">
            </template>
          </sov-slide>
        </slide>
        <slide :key="4" style="background: #E8F2E1;overflow: hidden" class="px-10 py-10 rounded-2xl md:mr-5">
          <sov-slide header="Mittagsbetreuung Grundschule Wertingen">
            <template #text>
              Seit dem Schuljahr 2019/20 führen wir als Kooperationspartner des Schulverbandes Wertingen die erweiterte
              Mittagsbetreuung an der Grundschule Wertingen durch.
              <img :src="require('/src/assets/images/more_button.png')" alt="Mehr"
                   class="h-16 absolute right-0 bottom-0 z-50 cursor-pointer"
                   style="margin-right: -10px; margin-bottom: -10px" @click="modalStore.openSchuleWertingen" v-touch="modalStore.openSchuleWertingen">
            </template>
          </sov-slide>
        </slide>
        <slide :key="5" style="background: #CDE3CD;overflow: hidden" class="px-10 py-10 rounded-2xl md:mr-5">
          <sov-slide header="Betriebliche Ferienbetreuung">
            <template #text>
              Machen auch Sie Ihr Unternehmen für Ihre Mitarbeiter*innen mit einer Betrieblichen Ferienbetreuung
              nachhaltig attraktiv.
              <img :src="require('/src/assets/images/more_button.png')" alt="Mehr"
                   class="h-16 absolute right-0 bottom-0 z-50 cursor-pointer"
                   style="margin-right: -10px; margin-bottom: -10px" @click="modalStore.openFerienBetreuung" v-touch="modalStore.openFerienBetreuung">
            </template>
          </sov-slide>
        </slide>
        <template #addons>
          <navigation/>
          <pagination/>
        </template>
      </carousel>
    </div>
  </div>
  <div class="flex justify-center">
      <img :src="require('/src/assets/images/thumbs_up.png')" alt="Zusammenhalt"
           class="pt-10 px-10 pb-10 md:pb-0 md:px-40 rounded-2xl w-full hidden md:block" style="max-width: 1680px">
    <img :src="require('/src/assets/images/thumbs_up_mobile.png')" alt="Zusammenhalt"
         class="pt-10 px-10 pb-10 md:pb-0 md:px-40 rounded-2xl w-full md:hidden">
  <div class="" id="about-us"></div>
  </div>
  <el-dialog
      v-model="modalStore.showLeasingDillingen"
      width="50%"
      :fullscreen="width < 700"
  >
    <leasing-dillingen></leasing-dillingen>
  </el-dialog>
  <el-dialog
      v-model="modalStore.showLeasingAllgauu"
      width="50%"
      :fullscreen="width < 700"
  >
    <leasing-allgauu></leasing-allgauu>
  </el-dialog>
  <el-dialog
      v-model="modalStore.showSchuleDillingen"
      width="50%"
      :fullscreen="width < 700"
  >
    <schule-dillingen></schule-dillingen>
  </el-dialog>
  <el-dialog
      v-model="modalStore.showSchuleWertingen"
      width="50%"
      :fullscreen="width < 700"
  >
    <schule-wertingen></schule-wertingen>
  </el-dialog>
  <el-dialog
      v-model="modalStore.showFerienBetreuung"
      width="50%"
      :fullscreen="width < 700"
  >
    <ferien-betreuung></ferien-betreuung>
  </el-dialog>
</template>

<script>
import SectionHeader from '../theme/SectionHeader';
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';
import SovSlide from "./SovSlide";
import {useModalStore} from "../../store/modals/modalStore";
import LeasingDillingen from "../modals/LeasingDillingen";
import LeasingAllgauu from "../modals/LeasingAllgauu";
import SchuleDillingen from "../modals/SchuleDillingen";
import SchuleWertingen from "../modals/SchuleWertingen";
import FerienBetreuung from "../modals/FerienBetreuung";
import { ElDialog } from 'element-plus'
import 'element-plus/lib/components/dialog/style/css'
import { useWindowSize } from 'vue-window-size';

export default {
  name: 'OurJob',
  components: {
    FerienBetreuung,
    SchuleWertingen,
    SchuleDillingen,
    LeasingAllgauu,
    LeasingDillingen,
    SovSlide,
    SectionHeader,
    Navigation,
    Pagination,
    Slide,
    Carousel,
    ElDialog
  },
  setup() {
    const modalStore = useModalStore();
    const { width } = useWindowSize();

    return {modalStore, width}
  },
  data() {
    return {
      breakpoints: {
        // 700px and up
        1: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
        1024: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        1440: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
      },
    }
  }
};
</script>

<style>
@media only screen and (min-width: 1024px) {
  .our-job-slider > .carousel__viewport > .carousel__track {
    margin-right: 80px !important;
  }
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.carousel__pagination-button {
  @apply bg-sov-green-50
}

.carousel {
  text-align: unset;
  hyphens: auto;
}
.our-job-slider > .carousel__prev, .our-job-slider > .carousel__next, .our-job-slider > .carousel__pagination-button--active {
  @apply bg-sov-green;
  margin-top: -25px;
}
.carousel__prev, .carousel__next, .carousel__pagination-button--active {
  @apply bg-sov-green;
  margin-top: -55px;
}
</style>
