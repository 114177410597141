<template>
  <header class="sticky top-0 z-50">
    <nav class="bg-white dark:bg-gray-800 py-5">
      <div
          class="
      container
      px-3
      py-3
      mx-auto
      md:flex md:justify-between md:items-center
    "
      >
        <div class="flex items-center justify-between">
          <div class="text-left">
            <a
                class=""
                href="#"
            >
              <img :src="require('/src/assets/logo.png')" alt="Soviko Logo" class="h-16 pl-10">
            </a>
          </div>

          <!-- Mobile menu button -->
          <div class="flex md:hidden absolute right-5">
            <button
                type="button"
                class="
            text-gray
            dark:text-gray-200
            hover:text-primary
            dark:hover:text-gray-400
            focus:outline-none focus:text-gray-600
            dark:focus:text-gray-400
          "
                @click="toggleNav"
                aria-label="toggle menu"
            >
              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                <path
                    fill-rule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
        <div class="items-center md:flex" :class="navOpen == true ? 'block' : 'hidden'">
          <div class="flex flex-col md:flex-row md:mx-6 font-bold mt-5 md:mt-10 md:text-12px lg:text-sm">
            <a
                class="
              text-lg
            my-1
            text-gray
            dark:text-gray-200
            hover:text-primary
            dark:hover:text-primary
            lg:mx-6 md:my-0
          "
                href="#our-offer"
                v-smooth-scroll
                @click="toggleNav"
            >UNSER ANGEBOT</a
            >
            <a
                class="
            text-lg
            my-1
            text-gray
            dark:text-gray-200
            hover:text-primary
            dark:hover:text-primary
            lg:mx-6 md:my-0 md:mx-4
          "
                href="#our-job"
                v-smooth-scroll
                @click="toggleNav"
            >UNSERE TÄTIGKEIT</a
            >
            <a
                class="
                            text-lg

            my-1
            text-gray
            dark:text-gray-200
            hover:text-primary
            dark:hover:text-primary
            lg:mx-6 md:my-0 md:mx-4
          "
                href="#about-us"
                v-smooth-scroll
                @click="toggleNav"
            >ÜBER UNS</a
            >
            <a
                class="text-lg
            my-1
            text-gray
            dark:text-gray-200
            hover:text-primary
            dark:hover:text-primary
            lg:mx-6 md:my-0 md:mx-4
          "
                href="#contact"
                v-smooth-scroll
                @click="toggleNav"
            >KONTAKT</a>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import {ref} from 'vue';

export default {
  name: 'Navigation',
  setup() {
    const navOpen = ref(false);

    const toggleNav = () => {
      navOpen.value = !navOpen.value;
    };

    return {navOpen, toggleNav};
  },
};
</script>

<style scoped>

</style>
