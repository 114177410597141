<template>
  <div class="text-center">
    <div class="text-xl md:text-2xl pb-5 font-bold text-primary absolute top-10 md:top-5 text-center right-5 md:right-0 px-5">{{ header}}</div>
    <div class="text-left pt-16">
     <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SovSlide',
  props: ['header'],
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
</style>
