import { defineStore } from 'pinia'

// main is the name of the store. It is unique across your application
// and will appear in devtools
export const useModalStore = defineStore('modalStore', {
    // a function that returns a fresh state
    state: () => ({
        showSocial: false,
        showLeasingDillingen: false,
        showLeasingAllgauu: false,
        showSchuleDillingen: false,
        showSchuleWertingen: false,
        showFerienBetreuung: false,
        showOurValues: false,
        showImpress: false,
        showDataProtection: false
    }),
    // optional getters
    getters: {},
    // optional actions
    actions: {
        triggerSocial(state) {
            // `this` is the store instance
            this.showSocial = state
        },
        openLeasingDillingen() {
            this.showLeasingDillingen = true;
        },
        closeLeasingDillingen() {
            this.showLeasingDillingen = false;
        },
        openLeasingAllgauu() {
            this.showLeasingAllgauu = true;
        },
        closeLeasingAllgauu() {
            this.showLeasingAllgauu = false;
        },
        openSchuleDillingen() {
            this.showSchuleDillingen = true;
        },
        closeSchuleDillingen() {
            this.showSchuleDillingen = false;
        },
        openSchuleWertingen() {
            this.showSchuleWertingen = true;
        },
        closeSchuleWertingen() {
            this.showSchuleWertingen = false;
        },
        openFerienBetreuung() {
            this.showFerienBetreuung = true;
        },
        closeFerienBetreuung() {
            this.showFerienBetreuung = false;
        },
        openOurValues() {
            this.showOurValues = true;
        },
        closeOurValues() {
            this.showOurValues = false;
        },
        openImpress() {
            this.showImpress = true;
        },
        openDataProtection() {
            this.showDataProtection = true;
        },
    },
})
