<template>
  <div class="modal">
    <div class="modal-header">
      Jugendpflegeleasing in Immenstadt i. Allgäu
    </div>
    <div class="break-normal">
      <h3>Jugendhaus Immenstadt</h3>
      An fünf Tagen in der Woche hat das Jugendhaus Immenstadt geöffnet. Die Zielgruppe sind Jugendliche und junge
      Erwachsene zwischen 12 und 27 Jahren. Mehrere Räume mit toller Ausstattung sorgen für ein gemeinschaftliches
      Miteinander. Billard, Dart, Airhockey, Kicker uvm. laden zum gemeinsamen Zeitvertreib ein.
      <br><br>
      Ständig wechselnde Angebote wie z.B. Turniere, Aktionen und spannende Projekte, bei denen die jungen Besucher
      mitwirken können, runden das Profil des Hauses ab.
      <br><br>
      <span class="underline">Die Öffnungszeiten des Jugendhauses sind:</span> <br>
      <table>
        <tr>
          <td class="pr-10">Dienstag, Mittwoch, Donnerstag & Freitag</td>
          <td>14.30 bis 20 Uhr</td>
        </tr>
        <tr>
          <td>Samstag</td>
          <td>12 bis 18 Uhr</td>
        </tr>
      </table>
    </div>
    <div class="pt-10">
      <h3>Sozialraumorientierte Jugendarbeit</h3>
      Die Jugendpflege ist auch im Sozialraum in Immenstadt präsent. Die Jugendarbeiter sind also da, wo sich die jungen
      Menschen aufhalten. Ziel ist es, durch Beziehungsarbeit die Interessen und Bedürfnisse der Jugendlichen und jungen
      Erwachsenen aufzugreifen und Möglichkeiten zu finden, diese umzusetzen.
    </div>
    <div class="pt-10">
      <h3>Das Team der Stadtjugendpflege Immenstadt besteht aus</h3>
      - Jan Lucas Gavalas <br>
      - Johanna Grill <br>
      - Lucia Römer
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeasingAllgauu',
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
</style>
