<template>
  <div class="modal">
    <div class="modal-header">
      Unsere Werte
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-10 pb-10 md:gap-20 md:pb-20 break-normal">
      <div>
        <h3 class="pb-2">Ehrlichkeit und Anstand</h3>
        <div>
          <ul class="list-disc pl-5">
            <li class="py-1">Wir führen unser Unternehmen sorgfältig, ehrlich und nachhaltig und halten uns dabei streng an die geltenden Gesetze.</li>
            <li class="py-1">Wir übervorteilen weder Kunden noch Mitarbeiter.</li>
            <li class="py-1">Wir übernehmen Verantwortung für unsere Mitarbeiter und für Auswirkungen und Konsequenzen der Handlungen unseres Unternehmens.</li>
            <li class="py-1">Wir arbeiten nicht mit unanständig agierenden Unternehmen zusammen, die die hier aufgestellten ethischen Grundsätze wissentlich missachten.</li>
          </ul>
        </div>
      </div>
      <div>
        <h3 class="pb-2">Integrität und Zuverlässigkeit</h3>
        <ul class="list-disc pl-5">
          <li class="py-1">Wir verhandeln mit unseren Kunden auf der Basis einer fairen Preisstrategie. Zu einer guten Leistung gehört auch ein guter Preis.</li>
          <li class="py-1">Wir kalkulieren gründlich, liefern gute Qualität und rechnen korrekt ab.</li>
          <li class="py-1">Getroffene Vereinbarungen und Zusagen halten wir ein.</li>
          <li class="py-1">Wir erlauben uns, ein Geschäft auch abzulehnen, das nicht unseren Werten entspricht. Nicht jedes Geschäft muss gemacht werden</li>
        </ul>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20">
      <div>
        <h3 class="pb-2">Wertschätzung und Nachhaltigkeit</h3>
        <ul class="list-disc pl-5">
          <li class="py-1">Wir achten die Würde aller Menschen, in Wort und Tat.</li>
          <li class="py-1">Wir versprechen, Schaden von unseren Kunden und Mitarbeitern fern zu halten.</li>
          <li class="py-1">Wir achten den Wert von Umwelt und Natur, wollen einen Beitrag leisten für eine bessere Welt.</li>
          <li class="py-1">Wir gehen sorgfältig mit vorhandenen Ressourcen um und achten auf die Umwelt.</li>
        </ul>
      </div>
      <div>
        <h3 class="pb-2">Gemeinnützigkeit</h3>
        <ul class="list-disc pl-5">
          <li class="py-1">Ziel und Zweck unseres Handeln ist die Förderung des Allgemeinwohls der Gesellschaft, aber auch die Schaffung eines wirklichen Nutzens für unsere Kunden.</li>
          <li class="py-1">Dabei agieren wir gemäß unserem Namen sozial, visionär und kompetent.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OurValues',
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
</style>
