<template>
  <div class="modal">
    <div class="modal-header">
      Jugendpflegeleasing in Dillingen
    </div>
    <div class="break-normal">
      <h3>Jugendcafé Dillingen</h3>
      An drei Tagen in der Woche bietet das Jugendcafé auf knapp 200 m² verschiedenste Angebote zur sinnvollen
      Freizeitbeschäftigung. Vorhanden sind ein Billardtisch, ein Dartautomat, eine Tischtennisplatte, eine
      Spielekonsole, ein großer Tanzraum samt toller Musik- und Lichtanlage sowie viele Karten- und Brettspiele.
      <br><br>
      In regelmäßigen Abständen werden gemeinsame Turnier, Aktionen und Projekte veranstaltet.
      <br><br>
      Highlights im Jahr sind die Dillinger Kinderspielstadt, das Dillinger Hallenfußballturnier und das Dillinger
      Wizard-Turnier.
      <br><br>
      <span class="underline">Die Öffnungszeiten des Jugendcafés:</span> <br>
      <table>
        <tr>
          <td class="pr-10">Dienstag</td>
          <td>14 bis 19 Uhr</td>
        </tr>
        <tr>
          <td>Mittwoch</td>
          <td>14 bis 20 Uhr</td>
        </tr>
        <tr>
          <td>Freitag</td>
          <td>14 bis 21 Uhr</td>
        </tr>
      </table>
    </div>
    <div class="pt-10">
      <h3>Mobile Jugendsozialarbeit im öffentlichen Raum</h3>
      Die Jugendpflege sucht junge Menschen im öffentlichen Raum auf und stellt eine tragfähige Beziehung her. Ziel ist
      es, die Bedürfnisse und Anliegen der Jugendlichen aufzugreifen, aber auch mögliche Störungen anzusprechen und
      Lösungsstrategien zu entwickeln.
    </div>
    <div class="pt-10">
      <h3>Dillinger Kinderspielstadt</h3>
      Mit knapp 200 Kindern zwischen 6 und 13 Jahren ist die zweiwöchige Kinderspielstadt eine attraktive
      Ferienbetreuungsmaßnahme, die von der Jugendpflege geplant, organisiert und betreut wird. Zur Seite stehen 40
      ehrenamtliche Helfer*innen, die über die Jugendpflege gewonnen und qualifiziert werden.
    </div>
    <div class="pt-10">
      <h3>Das Team der Stadtjugendpflege Dillingen besteht aus</h3>
      - Réka Kovács <br>
      - Yaren Özdemir <br>
      - Matthias Grätsch
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeasingDillingen',
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
</style>
