<template>
  <section-header title="Über uns" image-button="button_kuemmern.png" image-button-position="right"></section-header>
  <div class="flex justify-center">
    <div class="soviko-container">
      <div class="flex justify-center">
        <div class="text-center text-gray pb-10 px-10 md:px-0 md:w-7/12 md:text-lg">
          SoViKo ist ein modernes <span class="font-bold text-primary">Nonprofit-Dienstleistungsunternehmen</span> aus
          dem
          Bereich der Kinder- und Jugendarbeit,
          gegründet im Oktober 2012. Gründer und Geschäftsführer ist Matthias Grätsch, der in verschiedenen Feldern der
          Sozialarbeit mit Jugendlichen eine große Bandbreite an Kompetenz
          und
          Erfahrung gewinnen konnte. <br> <br>
          Unsere starken Teams sind jeweils vor Ort tätig.
        </div>
      </div>
      <div>
        <carousel :items-to-show="3" :breakpoints="breakpoints" class="mx-5">
          <slide :key="1" class="">
            <div>
              <img :src="require('/src/assets/images/team/juca_dillingen.jpg')" alt="" style="border-radius: 25px" class="px-2 transition duration-300 ease-in-out lg:opacity-50  transform hover:opacity-100">
              <div class="flex justify-center">
                <sov-button style="background: #E0DEDA;color:black; cursor: default" class="mt-5">
                  Unser Team im <br> Jugendcafé Dillingen
                </sov-button>
              </div>
            </div>
          </slide>
          <slide :key="2" class="">
            <div>
              <img :src="require('/src/assets/images/team/coming_soon.jpg')" style="border-radius: 25px" alt="" class="px-2 transition duration-300 ease-in-out lg:opacity-50  transform hover:opacity-100">
              <div class="flex justify-center">
                <sov-button style="background: #E0DEDA; color:black;cursor: default" class="mt-5">
                  Unser Team im <br> Jugendhaus Immenstadt
                </sov-button>
              </div>
            </div>
          </slide>
          <slide :key="3" class="">
            <div>
              <img :src="require('/src/assets/images/team/ogts_dillingen.jpg')" alt="" style="border-radius: 25px" class="px-2 transition duration-300 ease-in-out lg:opacity-50  transform hover:opacity-100">
              <div class="flex justify-center">
                <sov-button style="background: #E8F2E1; color: black; cursor: default" class="mt-5">Unser Team an der <br> Grundschule Dillingen
                </sov-button>
              </div>
            </div>
          </slide>
          <slide :key="4" class="">
            <div>
              <img :src="require('/src/assets/images/team/coming_soon.jpg')" style="border-radius: 25px" alt="" class="px-2 transition duration-300 ease-in-out lg:opacity-50  transform hover:opacity-100">
              <div class="flex justify-center">
                <sov-button style="background: #E8F2E1; color: black; cursor:default;" class="mt-5">Unser Team an der <br> Grundschule Wertingen
                </sov-button>
              </div>
            </div>
          </slide>
          <template #addons>
            <navigation/>
            <pagination/>
          </template>
        </carousel>
      </div>
      <div class="flex justify-center py-20">
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div class="w-full pb-20 md:pb-0">
            <div class="w-full flex justify-center">
              <img :src="require('/src/assets/images/matthias.png')" alt="" class="h-44">
            </div>
            <div class="md:text-lg mx-auto text-center md:text-left px-10 md:px-0">
              <p class="pt-8 font-bold"><span class="text-primary">Matthias Grätsch</span> | Geschäftsführer</p>
              <p class="">
                Dipl. Sozial-Päd. (FH), Dipl. Theol. (Univ.) <br> Traumaberater, Businesscoach
              </p>
              <br>
              <p class="font-bold text-primary">
                Erfahrung in Tätigkeitsfeldern
              </p>
              <div class="pl-8">
                <ul class="text-left" style="line-height: 1.1 !important;">
                  <li>Pädagogische Leitungsarbeit mit Dienst- und Fachaufsicht</li>
                  <li>Projekt- und Krisenmanagement</li>
                  <li>Offene Kinder- und Jugendarbeit</li>
                  <li>Stationäre Jugendhilfe, Jugendvollzug</li>
                  <li>Gremienarbeit auf politischer und kirchlicher Ebene</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="md:ml-20">
            <div class="w-full md:pl-16 flex justify-center">
              <img :src="require('/src/assets/images/reka.png')" alt="" class="h-44">
            </div>
            <div class="md:text-lg mx-auto text-center md:text-left px-10 md:px-0">
              <p class="pt-8 font-bold"><span class="text-primary">Réka Kovàcs</span> | Pädagogische Leiterin OGTS</p>
              <p class="py-3">
                Dipl. Sozialpädagogin (Univ.)
              </p>
              <br>
              <p class="font-bold text-primary">
                Erfahrung in Tätigkeitsfeldern
              </p>
              <div class="pl-8">
                <ul style="line-height: 1.1 !important;" class="text-left">
                  <li>Offene Kinder- und Jugendarbeit</li>
                  <li>Schulische Betreuungsangebote</li>
                  <li>Arbeit mit Menschen mit Beeinträchtigung</li>
                  <li>Jugendsozialarbeit an Schulen</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center text-lg md:text-2xl pt-10 px-10">
        <span class="font-bold">Unsere Werte sind uns sehr wichtig und leiten unser Denken und Handeln!</span> <br>
        <br>
        <span class="text-primary font-bold">Ehrlichkeit und Anstand, Integrität und Zuverlässigkeit, <br> Wertschätzung und Nachhaltigkeit sowie Gemeinnützigkeit</span>
        <br>
        <div @click="modalStore.openOurValues">
          <sov-button class="mt-10 text-lg"></sov-button>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
      v-model="modalStore.showOurValues"
      width="50%"
      :fullscreen="width < 700"
  >
    <OurValues></OurValues>
  </el-dialog>
</template>

<script>

import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';
import SectionHeader from "../theme/SectionHeader";
import SovButton from "../theme/SovButton";
import OurValues from "../modals/OurValues";
import {useModalStore} from "../../store/modals/modalStore";
import {ElDialog} from 'element-plus'
import 'element-plus/lib/components/dialog/style/css'
import {useWindowSize} from 'vue-window-size';

export default {
  name: 'AboutUs',
  components: {
    ElDialog,
    OurValues,
    SovButton,
    SectionHeader,
    Navigation,
    Pagination,
    Slide,
    Carousel
  },
  data() {
    return {
      breakpoints: {
        // 700px and up
        1: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
        1024: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        1440: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
    }
  },
  setup() {
    const modalStore = useModalStore();
    const {width} = useWindowSize();
    return {modalStore, width}
  }
}
</script>

<style scoped>
.carousel__pagination-button {
  margin-top: 50px !important;
}

ul {
  list-style: none; /* Remove default bullets */
}

ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #169D48; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 15px; /* Also needed for space (tweak if needed) */
  font-size: 30px;
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

@media only screen and (max-width: 700px) {
  ul li::before {
    margin-left: -0.5em; /* Also needed for space (tweak if needed) */
  }
}

</style>
