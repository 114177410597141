<template>
  <div class="modal">
    <div class="modal-header">
      Sozial
    </div>
    <div class="break-normal">
      Kinder und Jugendliche brauchen Unterstützung auf dem Weg in ein selbstbestimmtes Leben. Wir bieten in unseren
      Angeboten Begleitung und Hilfestellung, indem wir mit ihnen in Beziehung treten.
      <br><br>
      Unsere Jugendsozialarbeit bringt Kontinuität im Kontakt mit Kindern und Jugendlichen. Angeleitete Gruppenarbeit,
      Einzelfallhilfe und Beratung sowie Nachwuchsarbeit stellen wir dadurch sicher.
      <br><br>
      Wir richten unsere Jugendsozialarbeit an den Bedürfnissen und Erfordernissen der jungen Menschen aus. Zusammen
      mit ihnen und unseren Auftraggebern erarbeiten wir bedarfsgerechte Konzepte. Somit garantieren wir ein passendes
      Angebot für Kommune, Schule und junge Menschen.
    </div>
  </div>
</template>

<script>
export default {
  name: 'Social',
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
</style>
