<template>
<button :class="$props.class" :style="$props.style" class="bg-sov-green text-white px-5 py-3 rounded"><slot>Mehr erfahren</slot></button>
</template>

<script>
export default {
  name: 'SovButton',
  props: ['class', 'style']
};
</script>

<style scoped>

</style>
