<template>
  <div class="background-grient flex justify-center">
    <div class="soviko-container">
      <div class="grid grid-cols-1 md:grid-cols-3 py-10">
        <div class="flex justify-center md:justify-start">
          <img :src="require('/src/assets/logo.png')" alt="Soviko Logo" class="h-20">
        </div>
        <div class="text-white font-bold flex justify-center px-10 md:px-0 py-10 md:py-0">
          <div>
            <p class="text-2xl">SoViKo GmbH | Sozial - Visionär - Kompetent</p> <br>
            <p>Römerstraße 9 | 89407 Dillingen</p>
            <p>Tel.: <a href="tel:+4990744244595" class="underline">09074 / 42 44 595</a> | Fax: 09074 / 42 44 596</p>
            <p>Mail: <a href="mailto:info@soviko.de" class="underline">info@soviko.de</a></p>
          </div>
        </div>
        <div class="text-white font-bold relative flex justify-center md:justify-end pt-10 md:pt-0">
          <div class="absolute bottom-0">
            <a @click="modalStore.openDataProtection" class="pr-5 cursor-pointer">Datenschutz</a>
            <a class="cursor-pointer" @click="modalStore.openImpress">Impressum</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
      v-model="modalStore.showImpress"
      width="50%"
      top="20px"
      :fullscreen="width < 700"
  >
    <impress></impress>
  </el-dialog>
  <el-dialog
      v-model="modalStore.showDataProtection"
      width="50%"
      top="20px"
      :fullscreen="width < 700"
  >
    <data-protection></data-protection>
  </el-dialog>
</template>

<script>
import {ElDialog} from "element-plus";
import {useWindowSize} from 'vue-window-size';
import Impress from "../modals/Impress";
import {useModalStore} from "../../store/modals/modalStore";
import DataProtection from "../modals/DataProtection";

export default {
  name: 'SovFooter',
  components: {DataProtection, Impress, ElDialog},
  setup() {
    const {width} = useWindowSize();
    const modalStore = useModalStore();
    return {width, modalStore}
  }
}
</script>

<style scoped>
.background-grient {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(141, 194, 143, 1) 30%, rgba(22, 158, 72, 1) 100%);
}
</style>
