<template>
  <div class="text-center" style="max-width: 400px">
    <div>
      <div class="flex justify-center">
        <img :src="require(`/src/assets/images/${$props.image}`)" alt="" class="h-80">
      </div>
      <p class="text-center pt-5 text-2xl text-primary font-bold">{{ $props.header }}</p>
      <div class="text-center pt-5 px-20 md:px-10 text-gray relative " style="min-height: 220px;">
        {{ $props.text }}
        <div class="flex justify-center">
          <div class="pt-10 md:pt-0 md:absolute md:bottom-10">
            <slot name="button">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfferCard',
  components: {},
  props: ['image', 'header', 'text'],
};
</script>

<style scoped>

</style>
