<template>
  <section-header title="Unser Angebot" anchor="our-offer"></section-header>
  <div class="flex justify-center">
    <div class="soviko-container">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-10 lg:px-32">
        <offer-card image="kids.png" header="Sozial" :text="text1">
          <template #button>
            <button class="bg-sov-green text-white px-5 py-3 rounded" @click="openSocial">Mehr erfahren</button>
          </template>
        </offer-card>
        <offer-card image="kid_color.png" header="Visionär" :text="text2">
          <template #button>
            <button class="bg-sov-green text-white px-5 py-3 rounded" @click="openVision">Mehr erfahren</button>
          </template>
        </offer-card>
        <offer-card image="happy_kids.png" header="Kompetent" :text="text3">
          <template #button>
            <div class="" id="our-job"></div>
            <button class="bg-sov-green text-white px-5 py-3 rounded" @click="openCompetent">Mehr erfahren</button>
          </template>
        </offer-card>
      </div>
    </div>
  </div>
  <el-dialog
      v-model="showSocial"
      width="30%"
      :fullscreen="width < 700"
  >
    <social></social>
  </el-dialog>
  <el-dialog
      v-model="showVision"
      width="30%"
      :fullscreen="width < 700"
  >
    <vision></vision>
  </el-dialog>
  <el-dialog
      v-model="showCompetent"
      width="30%"
      :fullscreen="width < 700"
  >
    <competent></competent>
  </el-dialog>
</template>

<script>
import OfferCard from './OfferCard';
import SectionHeader from '../theme/SectionHeader';
import {ref} from "vue";
import Social from "../modals/Social";
import Vision from "../modals/Vision";
import Competent from "../modals/Competent";
import { ElDialog } from 'element-plus'
import 'element-plus/lib/components/dialog/style/css'
import { useWindowSize } from 'vue-window-size';

export default {
  name: 'OurOffer',
  components: {Competent, Vision, Social, SectionHeader, OfferCard, ElDialog},
  setup() {

    const showSocial = ref(false)
    const showVision = ref(false)
    const showCompetent = ref(false)

    const closeSocial = () => {
      showSocial.value = false;
    }
    const openSocial = () => {
      showSocial.value = true;
    }

    const closeVision = () => {
      showVision.value = false;
    }
    const openVision = () => {
      showVision.value = true;
    }

    const closeCompetent = () => {
      showCompetent.value = false;
    }
    const openCompetent = () => {
      showCompetent.value = true;
    }

    const { width } = useWindowSize();

    return {
      showSocial,
      closeSocial,
      openSocial,
      closeVision,
      openVision,
      showVision,
      showCompetent,
      closeCompetent,
      openCompetent,
      width
    }
  },
  data() {
    return {
      text1: 'Wir stellen die jungen Menschen in ihren Lebenswelten und ihren Herausforderungen in den Mittelpunkt.',
      text2: 'Wir entwickeln mit Kindern und Jugendlichen ihre Ideen und Visionen.',
      text3: 'Wir stehen für Fachlichkeit, Verbindlichkeit und Engagement.',
    };
  },
};
</script>

<style scoped>
</style>
