import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'

import 'vue-universal-modal/dist/index.css'

import VueUniversalModal from 'vue-universal-modal'
import VueSmoothScroll from 'vue3-smooth-scroll'
import Vue3TouchEvents from "vue3-touch-events";
import VueGtag from "vue-gtag";

let app = createApp(App);
app.use(VueUniversalModal, {
    teleportTarget: '#modals'
})

app.use(VueGtag, {
    bootstrap: false,
    config: { id: "UA-217416559-1" }
}, router)

app.use(router)
app.use(VueSmoothScroll)
app.use(Vue3TouchEvents)
app.use(createPinia())
app.mount('#app')
