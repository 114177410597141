<template>
  <div class="rounded-2xl">
    <div class="flex justify-center">
      <img :src="require('/src/assets/images/header.png')" alt="Intro Bild" class="rounded-2xl">
    </div>
    <div class="flex justify-center">
      <img :src="require('/src/assets/images/button_first.png')" alt="Grüner Button" class="h-40 md:h-80 -mt-60px md:-mt-170px">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Intro',
};
</script>

<style scoped>

</style>
