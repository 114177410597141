<template>
  <div class="mt-20 mb-10 py-10 bg-sov-green-10" :style="$props.style">
    <img :src="require(`/src/assets/images/${$props.imageButton}`)" alt="Grüner Button" class="h-36 md:h-72 absolute"
         :class="{'left-0 md:left-36' : $props.imageButtonPosition === 'left', 'right-0 md:right-36': $props.imageButtonPosition === 'right'}"
         style="margin-top: -140px" v-if="$props.imageButton">
    <p class="text-center text-3xl md:text-4xl text-primary font-bold">{{ $props.title }}</p>
  </div>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: ['title', 'imageButton', 'imageButtonPosition', 'style', 'anchor']
};
</script>

<style scoped>

</style>
