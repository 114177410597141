<template>
  <div class="modal">
    <div class="modal-header">
      Erweiterte Mittagsbetreuung Grundschule Wertingen
    </div>
    <div class="break-normal">
      Die erweiterte Mittagsbetreuung ist ein kostenpflichtiges Betreuungsangebot der Grundschule Wertingen im Anschluss
      an die Unterrichtszeit.
      <br><br>
      Wir betreuen in der Mittagsbetreuung 75 Kinder der ersten bis vierten Jahrgangsstufe. Die Betreuungszeiten gehen
      dabei bis 13 bzw. 14 Uhr in den kurzen Gruppen, aber auch bis 16 oder 17 Uhr in der langen Gruppe.
      <br><br>
      Wir bieten im Programm der Mittagsbetreuung gemeinsames Spielen und Basteln, aber auch Unterstützung der
      Schüler*innen bei der Erledigung ihrer Hausaufgaben.
      <br><br>
      Die Anmeldung für die Mittagsbetreuung erfolgt vor dem Beginn des jeweils kommenden Schuljahres über das
      Sekretariat der Grundschule Wertingen. Die Anmeldeunterlagen erhalten Sie dort und sind dort auch wieder
      abzugeben.

    </div>
  </div>
</template>

<script>
export default {
  name: 'SchuleWertingen',
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
</style>
