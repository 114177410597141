<template>
  <div class="modal">
    <div class="modal-header">
      Offene Ganztagesschule Grundschule Dillingen
    </div>
    <div class="break-normal">
      Die Offene Ganztagesschule (OGTS) ist ein schulisches Angebot an der Grundschule Dillingen. Es besteht aus
      Kurzgruppen (bis 13 bzw. 14 Uhr) und Langgruppen (bis 16 Uhr).
      <br><br>
      Wir betreuen insgesamt ca. 120 Kinder. Unser Team der OGTS gestaltet dabei das Programm mit gemeinsamen Lern-,
      Spiel- und Bastelangeboten für die Kinder der ersten bis vierten Jahrgangsstufe.
      <br><br>
      In den Langgruppen findet am Nachmittag eine Hausaufgabenhilfe statt. Die Schüler*innen bekommen dabei
      Unterstützung bei der Erledigung ihrer Hausaufgaben.
      <br><br>
      Die Anmeldung für die OGTS erfolgt in der Regel im April für das kommende Schuljahr ab September. Die Unterlagen
      erhalten Sie über die Grundschule Dillingen und sind auch dort wieder abzugeben.
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchuleDillingen',
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
</style>
