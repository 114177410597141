<template>
  <div class="modal">
    <div class="modal-header">
      Impressum
    </div>
    <div class="break-normal">
      <span class="text-primary">SoViKo gGmbH<br>
      Sozial – Visionär – Kompetent<br></span>
      Träger der freien Jugendhilfe gem. § 75 a SGB VIII
      <br><br>
      Römerstraße 9<br>
      89407 Dillingen
      <br><br>
      Tel.: 09074 / 42 44 595<br>
      Fax: 09074 / 42 44 596
      <br><br>
      <a class="text-primary" href="mailto:info@soviko.de">info@soviko.de</a><br>
      www.soviko.de
      <br><br>
      Geschäftsführer: Matthias Grätsch<br>
      Registergericht Augsburg: HRB 27349
      <br> <br>
      <h3>Streitschichtung</h3>

      Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
      https://ec.europa.eu/consumers/odr
      Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht bereit oder verpflichtet, an
      Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
      <br> <br>
      <h3>Haftung für Inhalte</h3>

      Als Diensteanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
      Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
      übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
      rechtswidrige Tätigkeit hinweisen.
      <br><br>
      Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
      hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
      Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
      umgehend entfernen.
      <br> <br>
      <h3>Haftung für Links</h3>
      Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
      können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für dieInhalte der verlinkten Seiten ist stets
      der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
      Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
      erkennbar.
      <br> <br>
      Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
      Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
      entfernen.
      <br> <br>

      <h3>Urheberrecht</h3>

      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
      Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
      des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und
      Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
      <br> <br>
      Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
      beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
      Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
      Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
      <br> <br>
      Quelle: eRecht24
    </div>
  </div>
</template>

<script>
export default {
  name: 'Impress',
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
</style>
