<template>
  <div class="modal">
    <div class="modal-header">
      Visionär
    </div>
    <div class="break-normal">
      Kinder und Jugendliche haben ihre eigenen Vorstellungen, wie für sie gelebte Gemeinschaft funktionieren kann.
      Ihre Ideen greifen wir auf und entwickeln sie mit ihnen zusammen. In konkreten Projekten, Aktionen und Maßnahmen
      setzen wir mit den jungen Menschen in die Tat um, was zuvor gemeinsam geplant und organisiert wurde. <br><br>

      Dabei sehen wir es als unsere Aufgabe an, die Situation der Kinder und Jugendlichen im Allgemeinen im Blick zu
      haben, speziell in ihren Lebenswelten. Es bedarf Kommunikation und Vernetzung auf pädagogischer, politischer und
      gesellschaftlicher Ebene. Erst so kommen die jungen Menschen zu Wort und Begegnung mit der Erwachsenenwelt
      findet statt.<br><br>

      So lernen Erwachsene von den Kindern und Jugendlichen, denn beide Seiten gehen aufeinander zu.
    </div>
  </div>
</template>

<script>
export default {
  name: 'Vision',
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
</style>
