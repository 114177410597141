<template>
  <div class="modal">
    <div class="modal-header">
      Kompetent
    </div>
    <div class="break-normal">
      Unser pädagogisch ausgebildetes und geschultes Personal orientiert sich an Erfahrungen und Standards aus
      verschiedenen Bereichen der Jugendsozialarbeit.
      <br><br>
      Regionaler Bezug, die Kenntnis der Situation von Kindern und Jugendlichen sowie eine intensive Vernetzung
      ermöglichen es uns, die folgenden Rahmenziele umzusetzen:
      <br><br>
      <span class="font-bold">Prävention</span> <br>
      Junge Menschen erhalten Unterstützung bevor sie auffällig werden. <br><br>

      <span class="font-bold">Partizipation</span> <br>
      Junge Menschen bestimmen und gestalten ihre Angebote selbst. So treten sie für ihre Wünsche, Bedürfnisse, Träume
      und Interessen ein. <br><br>

      <span class="font-bold">Emanzipation</span> <br>
      Junge Menschen lernen, sich kritisch eine eigene Meinung zu bilden. Dadurch probieren sie bereits ein
      eigenverantwortliches Leben aus. <br><br>

      <span class="font-bold">Integration</span> <br>
      Junge Menschen praktizieren ein friedliches Neben- und Miteinander verschiedener Gruppen, Cliquen und Kulturen
      und erleben somit die Vielfalt unserer Welt.
    </div>
  </div>
</template>

<script>
export default {
  name: 'Competent',
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
</style>
