<template>
  <section-header title="Kontakt" style="margin-bottom: 0"></section-header>
  <form ref="form" class="py-10 md:py-20" style="background: #E0DEDA" @submit.prevent="sendMail">
    <div class="flex justify-center">
      <input type="text" name="name" placeholder="Ihr Name"
             class="py-3 px-5 w-10/12 md:w-3/12 shadow focus:outline-none" v-model="name">
    </div>
    <div class="flex justify-center pt-5">
      <input type="text" name="email" placeholder="Ihre E-Mail-Adresse"
             class="py-3 px-5 w-10/12 md:w-3/12 shadow focus:outline-none" v-model="email">
    </div>
    <div class="flex justify-center pt-5">
      <textarea placeholder="Ihre Nachricht" name="message" style="resize:none"
                class="py-3 px-5 w-10/12 md:w-3/12 h-40 shadow focus:outline-none" v-model="message"></textarea>
    </div>
    <div class="flex justify-center pt-5">
      <sov-button style="width: 200px">Senden</sov-button>
    </div>
    <div class="flex justify-center pt-10" v-if="error">
      <div class="px-4 py-3 leading-normal text-red-700 bg-red-100 rounded-lg" role="alert">
        {{error}}
      </div>
    </div>
    <div class="flex justify-center pt-10" v-if="showAlert">
      <div class="px-4 py-3 leading-normal text-green-700 bg-green-100 rounded-lg" role="alert">
        Danke für Ihre Nachricht. Sie wurde erfolgreich abgeschickt.
      </div>
    </div>
  </form>
</template>

<script>
import SectionHeader from "../theme/SectionHeader";
import SovButton from "../theme/SovButton";
import emailjs from 'emailjs-com';

export default {
  name: 'Contact',
  components: {SovButton, SectionHeader},
  setup() {
    return {}
  },
  data() {
    return {
      name: '',
      error: '',
      email: '',
      message: '',
      showAlert: false
    }
  },
  methods: {
    sendMail() {

      if (this.email == '' || this.name == '' || this.message == '') {
        this.error = 'Bitte füllen Sie alle Felder aus.'
      } else if (!/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(this.email)) {
        this.error = 'Ungültige E-Mail-Adresse.'
      } else {

        this.error = ''
        try {

          emailjs.sendForm('service_nwcttic', 'template_i06vyuq', this.$refs.form,
              'user_dLZ4XDk8XO2iyjFEsohjz')

          this.showAlert = true;

          this.name = ''
          this.email = ''
          this.message = ''

        } catch (error) {
          console.log('ERROR')
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
