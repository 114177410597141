<template>
  <home></home>
</template>

<script>
import Home from './components/Home';

export default {
  name: 'App',
  components: {Home}
}
</script>

<style lang="scss">
@import "assets/style/index.css";
</style>
